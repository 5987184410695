<template>
  <div class="verify-wrap">
    <div class="box plain-shadow-on-desktop">
      <div class="page-title has-text-centered">Verify email</div>
      <div class="has-text-centered page-text">
        <div>We sent an email to</div>
        <div class="has-text-blue email">{{email}}</div>
      </div>
      <div class="verify-image is-flex is-justify-content-center">
        <img src="@assets/images/signup/verify.png" alt="verify-image">
      </div>
      <div class="has-text-centered page-text">
        <div>Please click the link in your email to verify your</div>
        <div>email address 😎</div>
      </div>
      <div class="resend has-text-centered">
        Didn't receive an email?
        <span class="has-text-blue has-text-weight-semibold resend-button is-clickable" @click="resendEmail">Resend</span>
      </div>
      <div class="wrong-account has-text-centered mt-5">
        Wrong account? Sign up
        <span class="is-clickable has-text-primary has-text-weight-semibold" @click="toSignupPage">here</span>.
      </div>
    </div>
    <div class="is-flex button-box">
      <b-button type="is-primary" rounded class="is-medium-height done-button" @click="verifyDone">I've done that</b-button>
    </div>
    <ConfirmModal title="Your email is not yet verified" text="Please verify your email first!" :showModal="showModal" @closeModal="showModal = false"></ConfirmModal>
    <ConfirmModal title="Email Verification Resent" text="You should receive the email shortly!" :showModal="showResendModal" @closeModal="showResendModal = false"></ConfirmModal>
  </div>
</template>
<script>
import { getInvestorData, resendEmailVerification } from '@api/signup'
import ConfirmModal from '@components/modal/confirm-modal.vue'
import { mapState } from 'vuex'
import { isOnboardingCompleted } from '@utils/common-methods/navigateWithRouteGuard'
import { embeddedInIos } from '@utils/common-methods/common'

export default {
  components: {
    ConfirmModal,
  },
  data() {
    return {
      email: '',
      showModal: false,
      showResendModal: false,
      isVerifying: false,
      isResendingEmail: false,
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
  },
  mounted() {
    this.getEmailFromUrl()
  },
  methods: {
    getEmailFromUrl() {
      this.email = decodeURIComponent(this.$route.query.email)
    },
    verifyDone() {
      if (this.isVerifying) return
      this.mixpanel.track('zspx8byn')
      this.isVerifying = true
      getInvestorData().then((data) => {
        this.isVerifying = false
        localStorage.setItem('investor', JSON.stringify(data))

        if (data.emailVerifiedAt) {
          if (embeddedInIos()) return window.webkit.messageHandlers.didSignUpEmailVerified.postMessage(true)
          const onboardingCompleted = isOnboardingCompleted(data)
          this.$router.push(onboardingCompleted ? '/dashboard' : '/signup/profile/1')
        } else {
          this.showModal = true
        }
      })
    },
    resendEmail() {
      if (this.isResendingEmail) return
      this.isResendingEmail = true
      resendEmailVerification({
        email: this.email,
      }).then((data) => {
        this.isResendingEmail = false
        if (data.success) this.showResendModal = true
      })
    },
    toSignupPage() {
      this.$router.push('/signup/create')
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.verify-wrap {
  padding: 0 25px 140px;
  > .box {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 26px;
    padding-bottom: 26px;
    box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.15);
    .page-title {
      font-size: 32px;
      font-weight: 600;
      line-height: 32px;
      margin-bottom: 24px;
      letter-spacing: 0.03em;
    }
    .page-text {
      font-size: 16px;
      line-height: 19px;
      .email {
        font-weight: 600;
      }
    }
    .verify-image {
      margin-top: 35px;
      margin-bottom: 33px;
      img {
        width: 224px;
      }
    }
    .resend {
      font-size: 14px;
      padding: 42px 20px 0 20px;
      color: #9185AE;
      .resend-button {
        font-weight: 400;
      }
    }
    .wrong-account {
      font-size: 14px;
      color: #9185AE;
    }
  }

  .button-box {
    margin-top: 1.5rem;
    justify-content: end;
    .done-button {
      width: 147px;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .modal {
    .modal-content {
      position: relative;
      border-radius: 12px;
      padding: 48px 0 24px;
      .icon-close {
        width: 18px;
        position: absolute;
        left: 16px;
        top: 14px;
      }
      .modal-header {
        font-weight: 600;
        font-size: 20px;
        padding-bottom: 20px;
      }
      .modal-text {
        font-size: 16px;
        padding-bottom: 30px;
      }
      .button-ok {
        width: 127px;
        font-size: 16px;
        height: 43px;
        border-radius: 12px;
      }
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .verify-wrap {
    padding-bottom: 40px;
    .button-box {
      justify-content: center;
      button {
        width: 70% !important;
      }
    }
  }
}
</style>
